import { render, staticRenderFns } from "./imgItem.vue?vue&type=template&id=3809aa20&scoped=true&"
import script from "./imgItem.vue?vue&type=script&lang=js&"
export * from "./imgItem.vue?vue&type=script&lang=js&"
import style0 from "./imgItem.vue?vue&type=style&index=0&id=3809aa20&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3809aa20",
  null
  
)

component.options.__file = "imgItem.vue"
export default component.exports