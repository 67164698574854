<template>
  <figure v-lazyload :class="[cssClass ,'image__wrapper']">
    <Circle5 :class="[cssClass ,'image__spinner']" />
    <img :class="cssClass" :data-url="source" alt="random image">
  </figure>
</template>

<script>
import { Circle5 } from "vue-loading-spinner";

export default {
  name: "ImageItem",
  components: {
    Circle5
  },
  props: {
    source: {
      type: String,
      required: true
    },
    cssClass: {
      type: String,
      required: false
    }
  }
};
</script>

<style scoped lang="scss">
.image {
  &__wrapper {
    &.loaded {
      .image {
        &__item {
          visibility: visible;
          opacity: 1;
        }

        &__spinner {
          display: none;
          width: 100%;
        }
      }
    }
  }
}
</style>
